import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import HomePage from './pages/HomePage';
import PricingPage from './pages/PricingPage';
import GalleryPage from './pages/GalleryPage';
import AboutPage from './pages/AboutPage';
import ServicesPage from './pages/ServicesPage';

import './App.css';
import ContactUsPage from './pages/ContactUsPage';

// images for preloading
import PackagesPhoto from './assets/images/PACKAGES PHOTO 1.jpg';
import AboutPhoto from './assets/images/ABOUT PHOTO 1.jpg';
import ContactPhoto from './assets/images/CONTACT PHOTO 1.jpg';

function App() {
  const [hasSplashed, setHasSplashed] = useState(false);

  useEffect(() => {
    [PackagesPhoto, AboutPhoto, ContactPhoto].forEach((imgSrc) => {
      const img = new Image();
      img.src = imgSrc;
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setHasSplashed(true);
    }, 1000);
  }, []);

  return (
    <BrowserRouter>
      <ScrollToTopOnNavigate />
      <Routes>
        <Route path="/" element={<HomePage hideSplash={hasSplashed} />} />
        <Route path="/galleries" element={<GalleryPage />} />
        <Route path="/packages" element={<PricingPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactUsPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

function ScrollToTopOnNavigate() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
