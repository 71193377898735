import { useState } from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';
import styled from 'styled-components';

const StyledNavLinks = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 1000px;
  height: 50px;

  text-align: right;
  padding: 16px 24px;
  z-index: 1;

  @media screen and (max-width: 600px) {
    display: none;
    height: 0;
  }
`;

const ExternalNavLink = styled.a`
  :not(:last-child) {
    margin-right: 24px;
  }

  position: relative;
  font-family: 'Work Sans';
  text-decoration: none;
  text-transform: uppercase;
  font-size: 15px;
  padding-bottom: 12px;
  border-bottom: 1px solid transparent;
  transition: all 0.2s ease-in-out;

  ::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 13px;
    left: 0;
    right: 0;
    top: 18px;
  }
  :hover {
    border-bottom-color: #d8c6b5;
    padding-bottom: 4px;
  }
`;

const NavLink = styled(RouterNavLink).attrs(() => ({
  style: ({ isActive }: { isActive: boolean }) =>
    isActive
      ? {
          borderBottomColor: '#d8c6b5',
          paddingBottom: 4,
        }
      : {},
}))`
  :not(:last-child) {
    margin-right: 24px;
  }

  position: relative;
  font-family: 'Work Sans';
  text-decoration: none;
  text-transform: uppercase;
  font-size: 15px;
  padding-bottom: 12px;
  border-bottom: 1px solid transparent;
  transition: all 0.2s ease-in-out;

  ::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 13px;
    left: 0;
    right: 0;
    top: 18px;
  }
  :hover {
    border-bottom-color: #d8c6b5;
    padding-bottom: 4px;
  }
`;

const MobileNavLinks = styled.div`
  display: none;
  @media screen and (max-width: 600px) {
    display: block;
  }

  position: relative;
  width: 100%;
  height: 50px;
  z-index: 100;
  text-align: right;
`;

const NavLinkDropdown = styled.div<{ show: boolean }>`
  position: absolute;
  top: 50px;
  width: 100%;
  display: flex;
  opacity: ${({ show }) => (show ? '1' : '0')};
  height: ${({ show }) => (show ? '260' : '0')}px;
  z-index: -1;
  flex-direction: column;
  padding-bottom: 12px;
  transition: all 0.3s;
  overflow: hidden;

  > * {
    padding: 12px 0;
  }

  ${NavLink} {
    margin-right: 24px;
    padding-top: 0;
  }
`;

const MenuButton = styled.button`
  font-size: 30px;
  padding: 6px 24px;
`;

const Header = styled.header<{ lightText?: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background-color: #ffffffaa;
  z-index: 10;

  @media screen and (max-width: 600px) {
    background-color: ${({ lightText }) => (lightText ? 'black' : 'white')};

    ${NavLinkDropdown} {
      background-color: ${({ lightText }) => (lightText ? 'black' : 'white')};
    }
  }

  a {
    color: ${({ lightText }) => (lightText ? 'white' : '#5e5e5e')};
  }
`;

export default function NavHeader({ style }: { style?: React.CSSProperties }) {
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <Header style={style}>
      <StyledNavLinks>
        <NavLink to="/">Home</NavLink>
        <NavLink to="/about">About</NavLink>
        <ExternalNavLink href="https://evertalefilms.pic-time.com/portfolio">
          Photos
        </ExternalNavLink>
        <NavLink to="/galleries">Films</NavLink>
        <NavLink to="/packages">Packages</NavLink>
        <NavLink to="/services">Vendor Services</NavLink>
        <NavLink to="/contact">Contact</NavLink>
      </StyledNavLinks>
      <MobileNavLinks>
        <MenuButton onClick={() => setShowDropdown((s) => !s)}>≡</MenuButton>
        <NavLinkDropdown show={showDropdown}>
          <div>
            <NavLink to="/">Home</NavLink>
          </div>
          <div>
            <NavLink to="/about">About</NavLink>
          </div>
          <ExternalNavLink href="https://evertalefilms.pic-time.com/portfolio">
            Photos
          </ExternalNavLink>
          <div>
            <NavLink to="/galleries">Films</NavLink>
          </div>
          <div>
            <NavLink to="/packages">Packages</NavLink>
          </div>
          <div>
            <NavLink to="/services">Vendor Services</NavLink>
          </div>
          <div>
            <NavLink to="/contact">Contact</NavLink>
          </div>
        </NavLinkDropdown>
      </MobileNavLinks>
    </Header>
  );
}
