import styled from 'styled-components';

import ContactUsForm from '../components/ContactUsForm';
import Slideshow from '../components/Slideshow';
import TitlePane from '../components/TitlePane';
import NavHeader from '../components/NavHeader';

import IntersectionPopContainer from './IntersectionPopContainer';
import { ButtonLink } from '../components/Button';
import Footer from '../components/Footer';

const MinorVideosContainer = styled.div`
  display: flex;
  max-width: 1920px;
  margin: 0 auto;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }

  > * {
    flex: 1 0 0%;
  }
`;

export default function HomePage({ hideSplash }: { hideSplash?: boolean }) {
  return (
    <div>
      <NavHeader />
      <TitlePane hideSplash={hideSplash} />
      <div style={{ marginTop: '4%' }}>
        <Slideshow />
      </div>
      <div>
        <IntersectionPopContainer>
          <div
            style={{
              position: 'relative',
              width: '100%',
              maxWidth: '1920px',
              aspectRatio: '2',
              margin: '0 auto',
            }}
          >
            <iframe
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                zIndex: 1,
              }}
              src={`https://player.vimeo.com/video/1000647058?h=f6c48fefeb&autopause=0&portrait=0&title=0&byline=0&loop=1${
                window.innerWidth >= 480 ? '&autoplay=1&mute=1' : ''
              }`}
              allowFullScreen
              allow="autoplay"
              title="Janet and Johnson"
            />
          </div>
        </IntersectionPopContainer>

        <MinorVideosContainer>
          <IntersectionPopContainer>
            <div
              style={{
                position: 'relative',
                width: '100%',
                maxWidth: '960px',
                paddingBottom: '42%',
                margin: '0 auto',
              }}
            >
              <iframe
                style={{
                  position: 'absolute',
                  width: '98%',
                  height: '100%',
                  zIndex: 1,
                }}
                src="https://player.vimeo.com/video/1001161022?h=29b650e976&autopause=0&portrait=0&title=0&byline=0"
                allowFullScreen
                allow="autoplay"
                title="Jenny and Josh"
              />
            </div>
          </IntersectionPopContainer>

          <IntersectionPopContainer>
            <div
              style={{
                position: 'relative',
                width: '100%',
                maxWidth: '960px',
                paddingBottom: '42%',
                margin: '0 auto',
              }}
            >
              <iframe
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  zIndex: 1,
                }}
                src="https://player.vimeo.com/video/1001049361?h=85b5bb6144&autopause=0&portrait=0&title=0&byline=0"
                allowFullScreen
                allow="autoplay"
                title="Fen and Jens Wedding Teaser"
              />
            </div>
          </IntersectionPopContainer>
        </MinorVideosContainer>
      </div>
      <div
        style={{
          position: 'relative',
          maxWidth: '800px',
          margin: '0 auto',
          paddingBottom: '150px',
        }}
      >
        <div style={{ textAlign: 'center', margin: '36px 0' }}>
          <ButtonLink to="/galleries">View more</ButtonLink>
        </div>
        <div style={{ paddingTop: '50px' }}>
          <ContactUsForm />
        </div>
        <Footer />
      </div>
    </div>
  );
}
