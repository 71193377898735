import { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

const SPLASH_TEXT = 'Evertale Films';
const BLINK_CYCLE_MS = 1250;
const INPUT_DELAY_MS = 100;
const START_BLINK_ANI_DURATION_MS = 1750;

const END_BLINK_ANI_DELAY_MS =
  START_BLINK_ANI_DURATION_MS + INPUT_DELAY_MS * SPLASH_TEXT.length;

const TOTAL_ANIMATION_DURATION_MS = END_BLINK_ANI_DELAY_MS + 1500;

const blink = keyframes`
    0% {
        background-color: #000000;
    }
    50% {
        background-color: #000000;
    }
    51% {
        background-color: #ffffff00;
    }
    100% {
        background-color: #ffffff00;
    }
`;

const fadeOut = keyframes`
    from {
        background-color: white;
    }

    to {
        background-color: transparent;
    }
`;

const SplashText = styled.div<{ shouldFadeOut?: boolean; hasFaded?: boolean }>`
  font-family: 'DM Serif Display';
  font-size: max(3.6vw, 54px);
  width: 100%;
  color: #5e5e5e;
  cursor: default;
  user-select: none;
  transition: color 1s;

  ${({ shouldFadeOut, hasFaded }) =>
    (shouldFadeOut || hasFaded) &&
    css`
      color: white;
    `}

  @media (max-width: 600px) {
    font-size: 50px;
  }
`;

const Cursor = styled.span<{ hasFaded?: boolean }>`
  display: inline-block;
  height: max(3.5vw, 54px);
  background-color: #000000;
  width: 0.2vw;
  margin-right: -1vw;
  margin-bottom: -0.5vw;

  animation: ${BLINK_CYCLE_MS}ms linear 1 ${blink},
    ${BLINK_CYCLE_MS}ms ease-in-out forwards ${END_BLINK_ANI_DELAY_MS}ms 2
      ${blink};

  ${({ hasFaded }) =>
    hasFaded &&
    css`
      opacity: 0;
    `}

  @media (max-width: 600px) {
    height: 50px;
    width: 0.75vw;
    margin-bottom: -2vw;
  }
`;

const StyledSplash = styled.div<{
  shouldFadeOut?: boolean;
  hasFaded?: boolean;
}>`
  position: ${({ hasFaded }) => (hasFaded ? 'absolute' : 'fixed')};
  top: 0;
  display: flex;
  height: 100%;
  width: 100%;
  background-color: white;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  z-index: 100;
  transition: z-index 1.5s;

  ${({ shouldFadeOut }) =>
    shouldFadeOut &&
    css`
      animation: 1.5s ease-in-out forwards ${fadeOut};
    `}

  ${({ hasFaded }) =>
    hasFaded &&
    css`
      background-color: transparent;
      z-index: -1;
    `}
`;

type SplashProps = {
  className?: string;
  onFaded?: () => void;
  skipAnimation?: boolean;
};

function Splash({ className, onFaded, skipAnimation }: SplashProps) {
  const [counter, setCounter] = useState(
    skipAnimation ? SPLASH_TEXT.length : 0
  );
  const [shouldFadeOut, setShouldFadeOut] = useState(false);
  const [hasFaded, setHasFaded] = useState(skipAnimation ?? false);

  useEffect(() => {
    setTimeout(
      () => {
        if (SPLASH_TEXT.length > counter) {
          setCounter(counter + 1);
        }
      },
      counter === 0 ? START_BLINK_ANI_DURATION_MS : INPUT_DELAY_MS
    );
  }, [counter]);

  useEffect(() => {
    if (skipAnimation) {
      return;
    }

    setTimeout(() => {
      setShouldFadeOut(true);
    }, TOTAL_ANIMATION_DURATION_MS);

    setTimeout(() => {
      setHasFaded(true);
      onFaded?.();
    }, TOTAL_ANIMATION_DURATION_MS + 1500);
    // eslint-disable-next-line
  }, []);

  return (
    <StyledSplash
      className={className}
      shouldFadeOut={shouldFadeOut}
      hasFaded={hasFaded}
    >
      <SplashText shouldFadeOut={shouldFadeOut} hasFaded={hasFaded}>
        {SPLASH_TEXT.substring(0, counter)}
        <Cursor hasFaded={hasFaded} />
      </SplashText>
    </StyledSplash>
  );
}

export default Splash;
