import Prewedding1 from '../assets/images/slideshow/PREWEDDING1.jpg';
import Prewedding3 from '../assets/images/slideshow/PREWEDDING3.jpg';
import Prewedding4 from '../assets/images/slideshow/PREWEDDING4.jpg';
import Prewedding5 from '../assets/images/slideshow/PREWEDDING5.jpg';
import Prewedding8 from '../assets/images/slideshow/PREWEDDING8.jpg';
import Prewedding9 from '../assets/images/slideshow/PREWEDDING9.jpg';
import Prewedding12 from '../assets/images/slideshow/PREWEDDING12.jpg';
import Prewedding13 from '../assets/images/slideshow/PREWEDDING13.jpg';
import Prewedding14 from '../assets/images/slideshow/PREWEDDING14.jpg';
import Prewedding15 from '../assets/images/slideshow/PREWEDDING15.jpg';

const SlideshowItems = [
  Prewedding1,
  Prewedding3,
  Prewedding4,
  Prewedding5,
  Prewedding8,
  Prewedding9,
  Prewedding12,
  Prewedding13,
  Prewedding14,
  Prewedding15,
];

export default SlideshowItems;
