import styled from 'styled-components';
import NavHeader from '../components/NavHeader';
import PageLayout from '../components/PageLayout';

import AboutPhoto from '../assets/images/ABOUT PHOTO 1.jpg';

const Text = styled.p`
  color: #5e5e5e;
`;

export default function AboutPage() {
  return (
    <PageLayout>
      <NavHeader />
      <img
        style={{ width: '100%', height: '350px', objectFit: 'cover' }}
        src={AboutPhoto}
        alt=""
      />
      <div>
        <Text>
          We pride ourselves on being reliable and down-to-earth. Our team
          produces high-quality photography and videography with excellent
          customer service that has received rave reviews from our clients. How
          do we do it? We have a deep understanding of what makes a wedding day
          and what our wedding couples want, which allows us to know how to
          capture the right moments. We also have an eye for detail so that we
          can capture the most beautiful moments unnoticed by others while still
          being unobtrusive.
        </Text>

        <Text>
          Our ideal client is someone who is looking for gorgeous photos that
          bring out your best aesthetic and you’re looking for a truly cinematic
          film masterfully crafted by an experienced team. You are dissuaded by
          wedding agencies who often rush jobs and assign you their next
          available shooter. Instead, you are- like us, long-term thinkers and
          value a dedicated and steadfast team who takes pride in delivering
          quality. If this sounds like you, we may be a match!
        </Text>

        <Text>
          Thank you for stopping by our website. Enjoy your wedding day and
          trust that at the end of the night you&apos;ll have amazing memories
          captured on our cameras.
        </Text>
      </div>
    </PageLayout>
  );
}
