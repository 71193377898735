import styled from 'styled-components';
import { ButtonLink } from '../components/Button';
import NavHeader from '../components/NavHeader';
import PageLayout from '../components/PageLayout';

const GalleryItems = [
  {
    title: 'Evertale 2023',
    videoId: '998092719',
  },
  {
    title: 'Beichan and Jasmine',
    videoId: '1001047275',
  },
  {
    title: 'Ivan and Wai',
    videoId: '1001046719',
  },
  {
    title: 'Kat and Andreas',
    videoId: '857487745',
  },
  {
    title: 'Jenny and Josh',
    videoId: '1001158885',
  },
  {
    title: 'Dan and Vivienne',
    videoId: '908545047',
  },
];

type GalleryItemProps = {
  className?: string;
  title: string;
  videoId: string;
};

const GalleryItemVideo = styled.iframe`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const StyledGalleryItem = styled(GalleryItem)`
  position: relative;
  width: 100%;
  max-width: 1000px;
  padding-top: calc(56.25% + 30px);
  margin-bottom: 30px;
`;

function GalleryItem({ className, title, videoId }: GalleryItemProps) {
  return (
    <div className={className}>
      <GalleryItemVideo
        src={`https://player.vimeo.com/video/${videoId}?autopause=0&portrait=0&title=0&byline=0`}
        allowFullScreen
      />
      <div>{title}</div>
    </div>
  );
}

export default function GalleryPage() {
  return (
    <PageLayout>
      <NavHeader />
      {GalleryItems.map((i) => (
        <StyledGalleryItem title={i.title} videoId={i.videoId} />
      ))}
      <div style={{ textAlign: 'center' }}>
        <ButtonLink to="/packages">View Packages</ButtonLink>
      </div>
    </PageLayout>
  );
}
