import { AnchorHTMLAttributes } from 'react';
import styled from 'styled-components';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';

type LinkProps = (
  | (RouterLinkProps & { href?: never })
  | (AnchorHTMLAttributes<HTMLAnchorElement> & { to?: never })
) & {
  openInNewTab?: boolean;
};

const Link = styled(UnstyledLink)`
  &,
  &:active,
  &:visited {
    color: #d8c6b5;
  }
`;

export default Link;

export function UnstyledLink(props: LinkProps) {
  const { children, openInNewTab, ...linkProps } = props;

  const otherProps: Pick<RouterLinkProps, 'rel' | 'target'> = {};

  if (openInNewTab) {
    otherProps.target = '_blank';
    otherProps.rel = 'noopener noreferrer';
  }

  if (linkProps.to) {
    return (
      <RouterLink {...linkProps} {...otherProps}>
        {children}
      </RouterLink>
    );
  } else {
    return (
      <a {...linkProps} {...otherProps}>
        {children}
      </a>
    );
  }
}
