import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import SectionHeader from './SectionHeader';

const defaultFormValues = {
  wedding_date: '',
  name: '',
  email: '',
  wedding_venue: '',
  photo_video_both: '',
  message: '',
  phone: '',
  how_did_you_find_us: '',
};

const defaultFormState = {
  submitSuccess: false,
  isSubmitting: false,
  submitError: '',
};

type TextInputProps = {
  className?: string;
  label: string;
  onChange?: (value: string) => void;
  value: string;
};

function UnstyledTextInput({
  className,
  label,
  onChange,
  value,
}: TextInputProps) {
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    onChange?.(e.target.value);
  };

  return (
    <div className={className}>
      <div>
        <label>{label}</label>
      </div>
      <input type="text" onChange={handleChange} value={value} />
    </div>
  );
}

const TextInput = styled(UnstyledTextInput)`
  margin-bottom: 32px;

  input {
    width: 100%;
    border: none;
    border-bottom: 1px solid #3d3d3d;
    outline: none;
    transition: all 0.5s;
    padding: 12px 0 6px;

    &:hover,
    &:focus {
      border-bottom-color: #cdaf95;
    }
  }
`;

type DropdownInputProps = {
  className?: string;
  label?: string;
  options: {
    label: string;
    value: string;
  }[];
  value: string;
  onChange: (value: string) => void;
};

const DropdownOption = styled.button`
  display: block;
  padding: 0 16px;
  height: 28px;
  width: 100%;
  text-align: left;

  :hover {
    background-color: #fafafa;
  }
`;

const DropdownOptionsList = styled.div<{ isExpanded: boolean }>`
  position: absolute;
  width: 100%;
  box-sizing: content-box;
  background-color: #ffffff;
  border: 1px solid #3d3d3d;
  border-top: 1px solid #ffffff;
  transform: translate(-1px, -2px);
  opacity: 0;
  overflow: hidden;
  z-index: -1;
  transition: all 0.3s ease-in-out;

  ${(props) =>
    props.isExpanded &&
    `
opacity: 1;
border-color: #cdaf95;
z-index: 1;
  `}
`;

const DropdownValue = styled.button<{ isExpanded: boolean }>`
  display: block;
  height: 28px;
  width: 100%;
  text-align: left;
  padding: 0 16px;

  ::after {
    position: absolute;
    display: block;
    top: 6px;
    right: 10px;
    content: '${(props) => (props.isExpanded ? `⋏` : `⋎`)}';
    color: #3d3d3d;
    z-index: 1;
  }
`;
// content: ${(props) => (props.isExpanded ? '⋀⋁⋎⋏' : '∨')};

const useClickOutside = (ref: React.RefObject<any>, callback: () => void) => {
  useEffect(() => {
    function handleClickOutside(e: MouseEvent) {
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
};

function UnstyledDropdownInput({
  className,
  label,
  options,
  value,
  onChange,
}: DropdownInputProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);

  useClickOutside(selectRef, () => setIsExpanded(false));

  const handleChange = (value: string) => {
    onChange(value);
    setIsExpanded(false);
  };

  return (
    <div className={className} ref={selectRef}>
      <div>
        <label>{label}</label>
      </div>
      <div
        style={{
          position: 'relative',
          border: `1px solid ${isExpanded ? '#cdaf95' : '#3d3d3d'}`,
          marginTop: '12px',
          height: '28px',
          transition: 'all 0.5s ease-in-out',
        }}
      >
        <DropdownValue
          isExpanded={isExpanded}
          type="button"
          onClick={() => {
            setIsExpanded((e) => !e);
          }}
        >
          {value || 'Please select an option'}
        </DropdownValue>
        <DropdownOptionsList isExpanded={isExpanded}>
          {options.map((o) => (
            <DropdownOption
              type="button"
              onClick={() => {
                handleChange(o.value);
              }}
            >
              {o.label}
            </DropdownOption>
          ))}
        </DropdownOptionsList>
      </div>
    </div>
  );
}

const DropdownInput = styled(UnstyledDropdownInput)`
  margin-bottom: 32px;

  select {
    margin-top: 12px;
  }
`;

const SubmitSuccessMessage = styled.div`
  margin-bottom: 24px;
  font-weight: 600;
`;

const SubmitErrorMessage = styled.div`
  margin-bottom: 24px;
  font-weight: 600;
  color: #ae4b4b;
`;

const SubmitButton = styled.button`
  color: white;
  background-color: #d9c5b4;
  padding: 12px 24px;
  width: 120px;

  &:disabled {
    background-color: #cecece;
  }
`;

const AvailabilityForm = styled.div`
  width: 100%;
  max-width: 480px;
  margin: 0 auto;

  h4 {
    text-align: center;
  }

  @media screen and (max-width: 600px) {
    padding: 0 18px;
  }
`;

const SERVICE_OPTIONS = [
  {
    label: 'Both photography and videography',
    value: 'Both photography and videography',
  },
  {
    label: 'Photography only',
    value: 'Photography only',
  },
  {
    label: 'Videography only',
    value: 'Videography only',
  },
];

const REFERRAL_OPTIONS = [
  {
    label: 'Internet search',
    value: 'Internet search',
  },
  {
    label: 'Instagram',
    value: 'Instagram',
  },
  {
    label: 'Facebook',
    value: 'Facebook',
  },
  {
    label: 'Referral',
    value: 'Referral',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

export default function ContactUsForm() {
  const [formValues, setFormValues] = useState(defaultFormValues);
  const [formState, setFormState] = useState(defaultFormState);

  const handleFormValueChange = (field: string, value: string) => {
    setFormValues((v) => ({ ...v, [field]: value }));
    setFormState((s) => ({ ...s, submitError: '' }));
  };

  const handleFormSubmit = async () => {
    setFormState({ ...defaultFormState, isSubmitting: true });

    try {
      const response = await fetch(
        'https://api.hsforms.com/submissions/v3/integration/submit/22176793/446cb22a-412d-4af2-84d0-2c8a5c73d6a1',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            fields: Object.keys(formValues).map((k) => ({
              name: k,
              value: formValues[k as keyof typeof formValues],
            })),
          }),
        }
      );

      if (!response.ok) {
        setFormState({
          ...defaultFormState,
          submitError:
            'Sorry, we had trouble trying to submitting your details. Please make sure all fields are filled in correctly or email us directly at andrew@evertalefilms.com.au',
          isSubmitting: false,
        });

        return;
      }
    } catch (e) {
      setFormState({
        ...defaultFormState,
        submitError:
          'Sorry, we had trouble trying to submitting your details. Please make sure all fields are filled in correctly or email us directly at andrew@evertalefilms.com.au',
        isSubmitting: false,
      });

      return;
    }

    setFormState({
      ...defaultFormState,
      submitSuccess: true,
      isSubmitting: false,
    });
  };

  return (
    <AvailabilityForm>
      <SectionHeader>Check Our Availability</SectionHeader>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleFormSubmit();
        }}
      >
        <TextInput
          label="Name"
          value={formValues.name}
          onChange={(v) => handleFormValueChange('name', v)}
        />
        <TextInput
          label="Wedding date (DD/MM/YYYY)"
          value={formValues.wedding_date}
          onChange={(v) => handleFormValueChange('wedding_date', v)}
        />
        <TextInput
          label="Phone"
          value={formValues.phone}
          onChange={(v) => handleFormValueChange('phone', v)}
        />
        <TextInput
          label="Email"
          value={formValues.email}
          onChange={(v) => handleFormValueChange('email', v)}
        />
        <DropdownInput
          label="Service required"
          options={SERVICE_OPTIONS}
          value={formValues.photo_video_both}
          onChange={(v) => handleFormValueChange('photo_video_both', v)}
        />
        <TextInput
          label="Message"
          value={formValues.message}
          onChange={(v) => handleFormValueChange('message', v)}
        />
        <DropdownInput
          label="How did you find out about us?"
          options={REFERRAL_OPTIONS}
          value={formValues.how_did_you_find_us}
          onChange={(v) => handleFormValueChange('how_did_you_find_us', v)}
        />

        {formState.submitSuccess && (
          <SubmitSuccessMessage>
            Thank you for submitting your details. We will contact you shortly.
          </SubmitSuccessMessage>
        )}

        {formState.submitError && (
          <SubmitErrorMessage>{formState.submitError}</SubmitErrorMessage>
        )}

        <SubmitButton
          type="submit"
          onClick={handleFormSubmit}
          disabled={formState.isSubmitting}
        >
          {formState.isSubmitting ? 'Submitting...' : 'Submit'}
        </SubmitButton>
      </form>
    </AvailabilityForm>
  );
}
