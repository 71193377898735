import styled from 'styled-components';

import NavHeader from '../components/NavHeader';
import PageLayout from '../components/PageLayout';
import Link from '../components/Link';

export default function PricingPage() {
  return (
    <PageLayout>
      <NavHeader />
      <div
        style={{
          position: 'relative',
          width: '100%',
          paddingBottom: '50%',
          margin: '0 auto',
        }}
      >
        <iframe
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: 1,
          }}
          src={`https://player.vimeo.com/video/1000647058?h=f6c48fefeb&autopause=0&portrait=0&title=0&byline=0&loop=1${
            window.innerWidth >= 480 ? '&autoplay=1&mute=1' : ''
          }`}
          allowFullScreen
          allow="autoplay"
          title="Rachel and Steven"
        />
      </div>
      <div>
        <p>
          Evertale Films videos are celebrated for their exceptional quality,
          earning enthusiastic praise from clients and industry colleagues
          alike.
        </p>
        <p>
          Beginning in 2024, we are thrilled to offer our premier
          post-production services to wedding videographers across Australia. We
          look forward to partnering with our amicable competitors, with the
          goal of delivering awe-inspiring films for all Australian couples.
        </p>
        <p>
          Please visit{' '}
          <Link href="http://www.acgmedia.com.au">www.acgmedia.com.au</Link> to
          sign up!
        </p>
      </div>
    </PageLayout>
  );
}
