import { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

import Splash from './Splash';
import Link from './Link';

import HomeImage1 from '../assets/images/HOME - CAROUSEL 1.jpg';
import HomeImage2 from '../assets/images/HOME - CAROUSEL 2.jpg';

import HomeImageMobile1 from '../assets/images/HOME - CAROUSEL MOBILE 1.jpg';
import HomeImageMobile2 from '../assets/images/HOME - CAROUSEL MOBILE 2.jpg';
import HomeImageMobile3 from '../assets/images/HOME - CAROUSEL MOBILE 3.jpg';

const CarouselContainer = styled.div`
  position: relative;
  width: calc(100%);
  height: calc(100vh);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  overflow: hidden;
`;

const TitleCta = styled(Link)<{ show?: boolean }>`
  font-size: max(1vw, 16px);
  border: 1px solid #ffffff66;
  padding: 8px 48px 6px;
  color: white;
  text-align: center;
  opacity: 0;
  margin-top: 80px;
  margin-bottom: -103px;
  transition: opacity 0.5s, background-color 0.25s, border-color 0.25s;

  :hover {
    background-color: #ffffff33;
    border-color: white;
  }

  @media (max-width: 600px) {
    font-size: 16px;
  }

  ${({ show }) =>
    show &&
    css`
      opacity: 1;
    `}
`;

const OverlayText = styled.div<{ show?: boolean }>`
  font-size: max(1.4vw, 20px);
  font-family: 'Amiri', serif;
  color: #ffffff;
  margin: 80px 0 4px;
  opacity: 0;
  transition: opacity 0.5s;

  ${({ show }) =>
    show &&
    css`
      opacity: 1;
    `}
`;

const slideIn = keyframes`
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0%);
    opacity: 1;
  }
`;

const slideOut = keyframes`
  from {
    transform: translateX(0%);
    opacity: 1;
  }

  to {
    transform: translateX(-100%);
    opacity: 0;
  }
`;

const CarouselItem = styled.img<{
  transitionIn?: boolean;
  transitionOut?: boolean;
  isMobile?: boolean;
}>`
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  display: block;
  ${({ isMobile }) =>
    isMobile &&
    css`
      display: none;
    `}

  width: 100%;
  height: 100%;

  @media screen and (max-width: 600px) {
    display: none;
    ${({ isMobile }) =>
      isMobile &&
      css`
        display: block;
      `}
  }

  ${({ transitionIn }) =>
    transitionIn &&
    css`
      animation: 0.5s ease-in-out forwards ${slideIn};
    `}
  ${({ transitionOut }) =>
    transitionOut &&
    css`
      animation: 0.5s ease-in-out forwards ${slideOut};
    `}
`;

const HomeCarousel = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: -2;
`;

export default function TitlePane({ hideSplash }: { hideSplash?: boolean }) {
  const [splashHasFaded, setSplashHasFaded] = useState(hideSplash ?? false);
  const [splashIndex, setSplashIndex] = useState(0);

  useEffect(() => {
    if (!splashHasFaded) {
      return;
    }

    const timer = setInterval(() => {
      setSplashIndex((i) => (i + 7) % 6);
    }, 5000);

    return () => clearInterval(timer);
  }, [splashHasFaded]);

  return (
    <CarouselContainer>
      <Splash
        skipAnimation={hideSplash}
        onFaded={() => setSplashHasFaded(true)}
      />

      <HomeCarousel>
        <CarouselItem
          src={HomeImage1}
          transitionIn={splashIndex % 2 === 0}
          transitionOut={splashIndex % 2 === 1}
        />
        <CarouselItem
          src={HomeImage2}
          transitionIn={splashIndex % 2 === 1}
          transitionOut={splashIndex % 2 === 0}
        />

        <CarouselItem
          src={HomeImageMobile1}
          isMobile
          transitionIn={splashIndex % 3 === 0}
          transitionOut={splashIndex % 3 !== 0}
        />
        <CarouselItem
          src={HomeImageMobile2}
          isMobile
          transitionIn={splashIndex % 3 === 1}
          transitionOut={splashIndex % 3 !== 1}
        />
        <CarouselItem
          src={HomeImageMobile3}
          isMobile
          transitionIn={splashIndex % 3 === 2}
          transitionOut={splashIndex % 3 !== 2}
        />
      </HomeCarousel>
      <TitleCta
        href="https://evertalefilms.pic-time.com/portfolio"
        show={splashHasFaded}
        openInNewTab
      >
        View Weddings
      </TitleCta>
    </CarouselContainer>
  );
}
