import ContactUsForm from '../components/ContactUsForm';
import NavHeader from '../components/NavHeader';
import PageLayout from '../components/PageLayout';

import ContactPhoto from '../assets/images/CONTACT PHOTO 1.jpg';

export default function ContactUsPage() {
  return (
    <PageLayout>
      <NavHeader />
      <div>
        <img
          style={{ width: '100%', height: '350px', objectFit: 'cover' }}
          src={ContactPhoto}
          alt=""
        />
        <ContactUsForm />
      </div>
    </PageLayout>
  );
}
