import { useRef, useState } from 'react';
import styled from 'styled-components';
import SlideshowItems from '../config/SlideshowItems';

const SlideshowContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: space-between;

  overflow: hidden;
`;

const Reel = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  height: 100vh;
  width: 100%;
  overflow-x: visible;
  z-index: -1;
`;

const ReelItem = styled.img<{ $offset: number; $zIndex: number }>`
  position: absolute;
  flex-grow: 0;
  flex-shrink: 0;
  height: 100%;
  margin: 0;
  max-width: 100vw;
  transform: translateX(${({ $offset }) => `${$offset * 100}%`});
  transition: ${({ $offset }) =>
    `${Math.abs($offset) < 4 ? 'transform 0.3s' : 'none'}`};
  opacity: ${({ $offset }) => `${Math.abs($offset) < 4 ? '1' : '0'}`};
  object-fit: cover;
  z-index: $zIndex;
`;

const NavigateReelButton = styled.button`
  color: white;
  font-size: 12px;
  height: 100%;
  width: 120px;
  text-transform: uppercase;
  letter-spacing: 2px;
  z-index: 1;
`;

export default function Slideshow() {
  const [reelItemIndex, setReelItemIndex] = useState(0);
  const touchStart = useRef([0, 0]);

  return (
    <SlideshowContainer
      onTouchStart={(e) => {
        touchStart.current = [
          e.changedTouches[0].screenX,
          e.changedTouches[0].screenY,
        ];
      }}
      onTouchEnd={(e) => {
        const deltaX = e.changedTouches[0].screenX - touchStart.current[0];
        const deltaY = e.changedTouches[0].screenY - touchStart.current[1];

        if (Math.abs(deltaX / deltaY) > 2) {
          setReelItemIndex(
            (i) =>
              (i + SlideshowItems.length - Math.sign(deltaX)) %
              SlideshowItems.length
          );
        }
      }}
    >
      <NavigateReelButton
        onClick={() =>
          setReelItemIndex(
            (reelItemIndex - 1 + SlideshowItems.length) % SlideshowItems.length
          )
        }
      >
        Previous
      </NavigateReelButton>
      <NavigateReelButton
        onClick={() =>
          setReelItemIndex(
            (reelItemIndex + 1 + SlideshowItems.length) % SlideshowItems.length
          )
        }
      >
        Next
      </NavigateReelButton>
      <Reel>
        {SlideshowItems.map((itemSrc, i) => {
          let wrappedDistance = i - reelItemIndex;
          if (wrappedDistance > 4) {
            wrappedDistance = wrappedDistance - SlideshowItems.length;
          } else if (wrappedDistance < -4) {
            wrappedDistance = wrappedDistance + SlideshowItems.length;
          }

          return (
            <ReelItem
              key={itemSrc}
              src={itemSrc}
              $offset={wrappedDistance}
              $zIndex={Math.max(0, 5 - Math.abs(wrappedDistance))}
            />
          );
        })}
      </Reel>
    </SlideshowContainer>
  );
}
