import styled from 'styled-components';

import Footer from './Footer';

const PageLayout = styled(UnstyledPageLayout)`
  position: relative;
  width: 100%;
  max-width: 1000px;
  min-height: 100%;
  padding: 50px 24px 150px;
  margin: 0 auto;
`;

function UnstyledPageLayout(props: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div {...props}>
      {props.children}
      <Footer />
    </div>
  );
}

export default PageLayout;
