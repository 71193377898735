import styled from 'styled-components';

import NavHeader from '../components/NavHeader';
import PageLayout from '../components/PageLayout';

import PackagesPhoto from '../assets/images/PACKAGES PHOTO 1.jpg';

const PhotographyProducts = [
  {
    header: 'Essential',
    items: [
      '8 Hour Coverage',
      '1 Photographer',
      'Unlimited photos',
      'Highlights Album (approx. 200 high res digital photos)',
      'Full Album (approx. 800 high res digital photos)',
      'Online gallery',
      'Travel included within Melbourne Metro areas',
      'Up to 20 photo enhancements',
      '12 months backup of deliverables',
      '$3680',
    ],
  },
  {
    header: 'Enchanted',
    items: [
      '14 Hour Coverage',
      '1 Photographer',
      'Unlimited photos',
      'Highlights Album (approx. 200 high res digital photos)',
      'Full Album (1000+ high res digital photos)',
      'Online gallery',
      'Travel included within Melbourne Metro areas',
      'Up to 40 photo enhancements',
      '12 months backup of deliverables',
      '$4900',
    ],
  },
];

const VideographyProducts = [
  {
    header: 'Essential',
    items: [
      '8 Hour Coverage',
      '2 Videographers',
      '1min Teaser edit',
      '4min Highlights edit',
      'Travel included within Melbourne Metro areas',
      '12 months backup of deliverables',
      '$3680',
    ],
  },
  {
    header: 'Enchanted',
    items: [
      '14 Hour Coverage',
      '2 Videographers',
      '1min Teaser edit',
      '5min Highlights edit',
      'Travel included within Melbourne Metro areas',
      '12 months backup of deliverables',
      '$4900',
    ],
  },
];

const CombinedProducts = [
  {
    header: 'Essential',
    items: [
      'All of the above',
      '$6500',
    ],
  },
  {
    header: 'Enchanted',
    items: [
      'All of the above',
      'Plus BONUS Pre-wedding photo and video shoot',
      '$9300',
    ],
  },
];

const AddOns = [
  'Extra Hour, $300/hr per service',
  'Social Media Pack - Same Day photos and video, $300',
  'Drone Videography, $150',
  'Raw Footage, $300',
  'Documentary Edit, $550',
  'Physical photo albums, Starting from $600',
];

const PackageSet = styled.div`
  display: flex;
  margin-bottom: 30px;

  > * {
    flex-basis: 50%;
  }

  @media screen and (max-width: 600px) {
    display: block;
  }
`;

type ProductPackageProps = {
  header: string;
  items: string[];
};

function ProductPackage({ header, items }: ProductPackageProps) {
  return (
    <div>
      <div style={{ fontWeight: 600 }}>{header}</div>
      <ul>
        {items.map((item) => (
          <li>{item}</li>
        ))}
      </ul>
    </div>
  );
}

export default function PricingPage() {
  return (
    <PageLayout>
      <NavHeader />
      <img
        style={{ width: '100%', height: '350px', objectFit: 'cover' }}
        src={PackagesPhoto}
        alt=""
      />
      <h3>Photography</h3>
      <PackageSet>
        {PhotographyProducts.map((p) => (
          <ProductPackage header={p.header} items={p.items} />
        ))}
      </PackageSet>
      <h3>Videography</h3>
      <PackageSet>
        {VideographyProducts.map((p) => (
          <ProductPackage header={p.header} items={p.items} />
        ))}
      </PackageSet>
      <h3>Combined Photography and Videography</h3>
      <PackageSet>
        {CombinedProducts.map((p) => (
          <ProductPackage header={p.header} items={p.items} />
        ))}
      </PackageSet>
      <h3>Add Ons</h3>
      <ul>
        {AddOns.map((item) => (
          <li>{item}</li>
        ))}
      </ul>
    </PageLayout>
  );
}
