import { useState } from 'react';
import styled from 'styled-components';

type IntersectionPopContainerProps = {
  children: React.ReactNode;
};

const Container = styled.div<{ isRevealed?: boolean }>`
  transition: all 0.5s;

  opacity: ${({ isRevealed }) => (isRevealed ? '1' : '0')};
  transform: translateY(${({ isRevealed }) => (isRevealed ? '0' : '30px')});
`;

export default function IntersectionPopContainer({
  children,
}: IntersectionPopContainerProps) {
  const [, setContainerRef] = useState<HTMLDivElement>();
  const [isRevealed, setIsRevealed] = useState(false);

  return (
    <Container
      isRevealed={isRevealed}
      ref={(ref) =>
        setContainerRef((prevRef) => {
          if (prevRef === ref || ref === null) {
            return ref ?? undefined;
          }

          const observer = new IntersectionObserver(
            (entries) => {
              if (entries[0].isIntersecting) {
                setIsRevealed(true);
              }
            },
            {
              root: null,
              threshold: [0.5],
            }
          );
          observer.observe(ref);

          return ref;
        })
      }
    >
      {children}
    </Container>
  );
}
