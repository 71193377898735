import styled from 'styled-components';

import FacebookIcon from './FacebookIcon';
import InstagramIcon from './InstagramIcon';

const Footer = styled(UnstyledFooter)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  margin: 30px 0 30px;
  font-size: 18px;

  svg {
    width: 26px;
    height: 26px;
    fill: #a9a9a9;
    margin: 8px;
  }
`;

const IconList = styled.div`
  display: flex;
  justify-content: center;
`;

type FooterProps = {
  className?: string;
};

function UnstyledFooter({ className }: FooterProps) {
  return (
    <div className={className}>
      <div>© Evertale Films 2022</div>
      <IconList>
        <a href="https://www.facebook.com/evertalefilms">
          <FacebookIcon />
        </a>
        <a href="https://www.instagram.com/evertalefilms">
          <InstagramIcon />
        </a>
      </IconList>
    </div>
  );
}

export default Footer;
