import styled from 'styled-components';
import { UnstyledLink } from './Link';

export const ButtonLink = styled(UnstyledLink)`
  background-color: white;
  color: #d9c5b4;
  border: 1px solid #d9c5b488;
  padding: 2px 24px;
  transition: all 0.3s ease-in-out;

  :hover {
    background-color: #d9c5b411;
    border-color: #d9c5b4;
  }
`;
